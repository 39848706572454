import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createReduxStore } from "./redux";
import { Provider } from "react-redux";
import { enableMapSet } from "immer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from "./App";
import "@/services/crisp.service";
import "./index.css";
import BotContextProvider from "@/pages/cbb-builder/store";

export const reduxStore = createReduxStore();
enableMapSet();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

createRoot(document.getElementById("root")!).render(
  // <StrictMode>
  <QueryClientProvider client={queryClient}>
    <Provider store={reduxStore}>
      <BotContextProvider>
        <App />
      </BotContextProvider>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  // </StrictMode>,
);
