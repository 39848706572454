import { State } from "./Types";

export const initialState: State = {
  user: {
    isLoggedIn: false,
    userName: "",
    userEmail: "",
    userPhone: "",
    userId: "",
    reload: false,
    switchLoad: false,
    boolValue: false,
    addClass: "app-collapse",
    settings: 0,
    showConfiguration: null,
    showBotAnalytics: false,
    showSegmentEdit: undefined,
    defaultCompany: false,
    companyDetails: {
      logo: "",
      compName: "",
      count: 0,
      createdAt: "",
      fromGemini: "false",
      fromMu: false,
      subDomain: "",
      updatedAt: "",
      userId: "",
      _id: "",
      chats: {
        selectedBotId: "",
        selectedBot: "",
        selectedChat: "",
        selectedSenderId: "",
        liveRoomIds: [],
      },
    },
    showCoupon: false,
    sessionCount: 0,
    infoModal: {
      showModal: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
    },
    successModal: false,
    successTitle: "",
    successMessage: "",
    errorModal: false,
    errorTitle: "",
    errorMessage: "",
    warningModal: false,
    warningTitle: "",
    warningMessage: "",
    showToast: false,
    toastType: "",
    toastMessage: "",
    toast: {
      show: false,
      toastType: "",
      message: "",
    },
    debug: false,
    beta: false,
    plan: {},
    role: "",
    compLogo: "",
    billing: {},
    trainingPending: false,
    trainingProgress: 0,
    trainingBotId: "",
    trainingIntervalId: 0,
    trainingType: "webpage",
    thrive: false,
    thriveData: {
      email: "",
      digest: "",
      userId: "",
    },
    socket: false,
    showHelloBar: false,
    textToWorkflowPrompt: "",
  },
  bot: {
    loading: true,
    liveLoading: false,
    _id: "",
    userId: "",
    companyId: "",
    botCustomId: "",
    botType: "web",
    startNodeId: "",
    liveBotId: "",
    projectName: "",
    url: "",
    status: "dev",
    workspaceId: "",
    workspaceName: "",
    count: 0,
    copiedFrom: "",
    isLive: false,
    emailConsent: false,
    cookieConsent: false,
    cookieConsentContent: "",
    emailConsentContent: "",
    questions: [{}],
    aiStarterQues: {},
    chatTranscript: false,
    aiBotConfig: {},
    embedConfig: {},
    chatConfig: {},
    aiSource: {},
    dataSources: {},
    createdAt: "",
    subDomain: "",
    updatedAt: "",
  },
  apps: {
    _id: "655af818b3daa660245dd008",
    active: true,
    nodeName: "OPEN_AI",
    name: "OPEN AI",
    type: "callAPI",
    callAPI: {
      configure: {
        account_attributes: ["api_key"],
        unique_identifier: "api_key",
        type: "APIkey",
        heading: "Connect to your Open AI account",
        description:
          "To connect Outgrow to Open AI, you will require your Open AI API Key and end point URL.",
        controls: [
          {
            type: "text",
            key: "config_name",
            placeholder: "Enter Config name",
            label: "Config Name",
            required: true,
            errorMsg: "Config name is required",
          },
          {
            type: "text",
            key: "api_key",
            placeholder: "Enter Open AI API key",
            label: "API key",
            required: true,
            errorMsg: "API key is required",
          },
          {
            type: "select",
            key: "api_key",
            placeholder: "Enter Open AI API key",
            label: "API key",
            required: true,
            errorMsg: "API key is required",
            options: ["GPT-3.4", "Davanci", "GPT-4"],
          },
        ],
      },
      authorization: {
        authorization_URL: "https://api.openai.com/v1/models",
        key_type: "Bearer",
        method: "GET",
      },
      endPoints: [
        {
          name: "Create Completion",
          type: "POST",
          URL: "https://api.openai.com/v1/completions",
          bodyTypeSupport: "FORM_DATA",
          JSONformat: null,
          headers: [
            {
              key: "Content-Type",
              value: "application/json",
              testValue: "",
              formType: "text",
              valueType: "string",
              optional: false,
              editable: false,
            },
          ],
          body: [
            {
              key: "model",
              value: "text-davinci-003",
              testValue: "",
              formType: "select",
              valueType: "string",
              valueList: [
                "text-davinci-003",
                "text-davinci-002",
                "text-curie-001",
                "text-babbage-001",
                "text-ada-001",
              ],
              min: null,
              max: null,
              description: "The model to use for completions",
              optional: false,
              variableList: false,
            },
            {
              key: "prompt",
              value: "Say this is text",
              testValue: "",
              formType: "text",
              valueType: "string",
              valueList: null,
              min: null,
              max: null,
              description: "The prompt to generate completions for",
              optional: false,
              variableList: true,
            },
            {
              key: "suffix",
              value: null,
              testValue: "",
              formType: "text",
              valueType: "string",
              valueList: null,
              min: null,
              max: null,
              description:
                "The suffix that comes after a completion of inserted text",
              optional: true,
              variableList: false,
            },
            {
              key: "max_tokens",
              value: 16,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 16,
              max: 4096,
              description:
                "The maximum number of tokens to generate in the chat completion (minimum 16, maximum 4096).",
              optional: true,
              variableList: false,
            },
            {
              key: "temperature",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 0,
              max: 2,
              description:
                "What sampling temperature to use. Higher values means the model will take more risks. Try 0.9 for more creative applications, and 0 (argmax sampling) for ones with a well-defined answer.",
              optional: true,
              variableList: false,
            },
            {
              key: "top_p",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 0,
              max: 1,
              description:
                "An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered. We generally recommend altering this or temperature but not both.",
              optional: true,
              variableList: false,
            },
            {
              key: "n",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 1,
              max: 10,
              description: "How many completions to generate for each prompt.",
              optional: true,
              variableList: false,
            },
            {
              key: "stop",
              value: null,
              testValue: "",
              formType: "text",
              valueType: "string",
              valueList: null,
              min: null,
              max: null,
              description:
                "Sequences where the API will stop generating further tokens. The returned text will not contain the stop sequence.",
              optional: true,
              variableList: false,
            },
            {
              key: "presence_penalty",
              value: 0,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: -2,
              max: 2,
              description:
                "Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.",
              optional: true,
              variableList: false,
            },
            {
              key: "frequency_penalty",
              value: 0,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: -2,
              max: 2,
              description:
                "Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.",
              optional: true,
              variableList: false,
            },
            {
              key: "user",
              value: "",
              testValue: "",
              formType: "text",
              valueType: "string",
              valueList: null,
              min: null,
              max: null,
              description:
                "A unique identifier representing your end-user, which can help OpenAI to monitor and detect abuse.",
              optional: true,
              variableList: false,
            },
            {
              key: "best_of",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 1,
              max: null,
              description:
                "Generates best_of completions server-side and returns the 'best' (the one with the highest log probability per token). If set, results cannot be streamed.",
              optional: true,
              variableList: false,
            },
          ],
          url_params: [],
          params: [],
        },
        {
          name: "Chat",
          type: "POST",
          URL: "https://api.openai.com/v1/chat/completions",
          bodyTypeSupport: "JSON",
          JSONformat: {
            messages: [
              {
                role: "user",
                content: "{{messages}}",
              },
            ],
          },
          headers: [
            {
              key: "Content-Type",
              value: "application/json",
              testValue: "",
              formType: "text",
              valueType: "string",
              optional: false,
              editable: false,
            },
          ],
          body: [
            {
              key: "model",
              value: "gpt-3.5-turbo",
              testValue: "",
              formType: "select",
              valueType: "string",
              valueList: [
                "gpt-4",
                "gpt-4-0314",
                "gpt-4-32k",
                "gpt-4-32k-0314",
                "gpt-3.5-turbo",
                "gpt-3.5-turbo-0301",
              ],
              min: null,
              max: null,
              description: "The model to use for completions",
              optional: false,
              variableList: false,
            },
            {
              key: "messages",
              value: "Hello!",
              testValue: "",
              formType: "text",
              valueType: "string",
              valueList: null,
              min: null,
              max: null,
              description:
                "The user messages provide instructions to the assistant. They can be generated by the end users of an application, or set by a developer as an instruction.",
              optional: false,
              variableList: true,
            },
            {
              key: "max_tokens",
              value: 16,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 16,
              max: 4096,
              description:
                "The maximum number of tokens to generate in the chat completion (minimum 16, maximum 4096).",
              optional: true,
              variableList: false,
            },
            {
              key: "temperature",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 0,
              max: 2,
              description:
                "What sampling temperature to use. Higher values means the model will take more risks. Try 0.9 for more creative applications, and 0 (argmax sampling) for ones with a well-defined answer.",
              optional: true,
              variableList: false,
            },
            {
              key: "top_p",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 0,
              max: 1,
              description:
                "An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered. We generally recommend altering this or temperature but not both.",
              optional: true,
              variableList: false,
            },
            {
              key: "n",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 1,
              max: 10,
              description: "How many completions to generate for each prompt.",
              optional: true,
              variableList: false,
            },
            {
              key: "stop",
              value: null,
              testValue: "",
              formType: "text",
              valueType: "string",
              valueList: null,
              min: null,
              max: null,
              description:
                "Sequences where the API will stop generating further tokens. The returned text will not contain the stop sequence.",
              optional: true,
              variableList: false,
            },
            {
              key: "presence_penalty",
              value: 0,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: -2,
              max: 2,
              description:
                "Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.",
              optional: true,
              variableList: false,
            },
            {
              key: "frequency_penalty",
              value: 0,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: -2,
              max: 2,
              description:
                "Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.",
              optional: true,
              variableList: false,
            },
            {
              key: "user",
              value: "",
              testValue: "",
              formType: "text",
              valueType: "string",
              valueList: null,
              min: null,
              max: null,
              description:
                "A unique identifier representing your end-user, which can help OpenAI to monitor and detect abuse.",
              optional: true,
              variableList: false,
            },
          ],
          url_params: [],
          params: [],
        },
        {
          name: "Edit",
          type: "POST",
          URL: "https://api.openai.com/v1/edits",
          bodyTypeSupport: "FORM_DATA",
          JSONformat: null,
          headers: [
            {
              key: "Content-Type",
              value: "application/json",
              testValue: "",
              formType: "text",
              valueType: "string",
              optional: false,
              editable: false,
            },
          ],
          body: [
            {
              key: "model",
              value: "text-davinci-edit-001",
              testValue: "",
              formType: "select",
              valueType: "string",
              valueList: ["text-davinci-edit-001", "code-davinci-edit-001"],
              min: null,
              max: null,
              description: "The model to use for completions",
              optional: false,
              variableList: false,
            },
            {
              key: "input",
              value: "What day of the wek is it?",
              testValue: "",
              formType: "text",
              valueType: "string",
              valueList: null,
              min: null,
              max: null,
              description:
                "The input text to use as a starting point for the edit.",
              optional: false,
              variableList: true,
            },
            {
              key: "instruction",
              value: "Fix the spelling mistakes",
              testValue: "",
              formType: "text",
              valueType: "string",
              valueList: null,
              min: null,
              max: null,
              description:
                "The instruction that tells the model how to edit the input.",
              optional: false,
              variableList: true,
            },
            {
              key: "temperature",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 0,
              max: 2,
              description:
                "What sampling temperature to use. Higher values means the model will take more risks. Try 0.9 for more creative applications, and 0 (argmax sampling) for ones with a well-defined answer.",
              optional: true,
              variableList: false,
            },
            {
              key: "top_p",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 0,
              max: 1,
              description:
                "An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered. We generally recommend altering this or temperature but not both.",
              optional: true,
              variableList: false,
            },
            {
              key: "n",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 1,
              max: 10,
              description: "How many completions to generate for each prompt.",
              optional: true,
              variableList: false,
            },
          ],
          url_params: [],
          params: [],
        },
        {
          name: "Create Image",
          type: "POST",
          URL: "https://api.openai.com/v1/images/generations",
          bodyTypeSupport: "FORM_DATA",
          JSONformat: null,
          headers: [
            {
              key: "Content-Type",
              value: "application/json",
              testValue: "",
              formType: "text",
              valueType: "string",
              optional: false,
              editable: false,
            },
          ],
          body: [
            {
              key: "prompt",
              value: "A cute baby sea otter",
              testValue: "",
              formType: "text",
              valueType: "string",
              valueList: null,
              min: null,
              max: null,
              description:
                "A text description of the desired image(s). The maximum length is 1000 characters.",
              optional: false,
              variableList: true,
            },
            {
              key: "size",
              value: "1024x1024",
              testValue: "",
              formType: "select",
              valueType: "string",
              valueList: ["256x256", "512x512", "1024x1024"],
              min: null,
              max: null,
              description: "The size of the generated images.",
              optional: true,
              variableList: false,
            },
            {
              key: "n",
              value: 1,
              testValue: "",
              formType: "number",
              valueType: "number",
              valueList: null,
              min: 1,
              max: 10,
              description: "How many completions to generate for each prompt.",
              optional: true,
              variableList: false,
            },
          ],
          url_params: [],
          params: [],
        },
      ],
    },
    __v: 0,
    logo: "https://dlvkyia8i4zmz.cloudfront.net/kGtrqrfoT9GlM1sEAZtD_image_1.png",
  },
};
