import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import environment from "./constants/Environment";
import Error404 from "./components/error404";
import RootLayout from "./components/layoutElements/RootLayout";
import AuthGuard from "./layouts/AuthGuard";
import React, { Suspense } from "react";
import Templates from "@/pages/templates/Templates";
import "./App.css";
import "./styles/introCustom.css";
import "./styles/main.css";
import "./styles/dashboard.css";
import "intro.js/introjs.css";
import "react-loading-skeleton/dist/skeleton.css";
import "@/styles/builder.css";
import "@/services/firebaseService";
import "@/services/facebook.service";
import ErrorElement from "./layouts/ErrorElement";
import Loader from "./components/loaders/Loader";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const environmentData: any = environment();

const SignUp = React.lazy(() => import("@/pages/authentication/SignUp"));
const Login = React.lazy(() => import("@/pages/authentication/Login"));
const Logout = React.lazy(() => import("@/pages/authentication/Logout"));
const Redirect = React.lazy(() => import("@/pages/authentication/Redirect"));
const Dashboard = React.lazy(() => import("@/pages/dashboard/Dashboard"));
const WorkflowBuilder = React.lazy(
  () => import("@/pages/omni-builder/BuilderContainer"),
);
const AiBotBuilder = React.lazy(
  () => import("@/pages/cbb-builder/BuilderContainer"),
);
const Preview = React.lazy(() => import("@/pages/preview/Preview"));
const Analytics = React.lazy(() => import("@/pages/analytics/Analytics"));
const Subscriptions = React.lazy(
  () => import("@/pages/integration/Integration"),
);
const Chats = React.lazy(() => import("@/pages/chat/Chat"));
const Settings = React.lazy(
  () => import("./components/layoutElements/settings"),
);
const WorkspaceDetails = React.lazy(
  () => import("./components/layoutElements/workspaceDetails"),
);
const Agent = React.lazy(() => import("@/pages/agent/Agent"));
const NewPassword = React.lazy(() => import("@/pages/newpassword/NewPassword"));
const NewUser = React.lazy(() => import("@/pages/newuser/NewUser"));
const ForgotPassword = React.lazy(
  () => import("@/pages/authentication/ForgotPassword"),
);
const SwitchAccount = React.lazy(
  () => import("@/pages/authentication/SwitchAccount"),
);
const Audience = React.lazy(() => import("@/pages/audience/Audience"));
const Calculator = React.lazy(() => import("./components/outgrowCalc"));
const Coupon = React.lazy(() => import("./components/coupon"));
const ExpiredTrial = React.lazy(
  () => import("./components/pendingPlans/plansDetails"),
);
const ChangePlan = React.lazy(
  () => import("./components/pendingPlans/plansDetails"),
);
const ExpiredPlan = React.lazy(
  () => import("./components/pendingPlans/billingDetails"),
);
const BotDebug = React.lazy(() => import("@/pages/debug"));
const RedirectUri = React.lazy(() => import("@/pages/redirectUri"));
const Otp = React.lazy(() => import("@/pages/Otp"));
const MagicLink = React.lazy(() => import("@/pages/MagicLink"));
const EntryMu = React.lazy(() => import("@/pages/authentication/EntryMu"));
const NewOnboarding = React.lazy(
  () => import("@/pages/AIOnboarding/NewOnboarding"),
);
const TextToWorkflow = React.lazy(
  () => import("@/pages/text-to-workflow/TextToWorkflow"),
);

export default function App() {
  return <RouterProvider router={router} />;
}

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: "*",
        element: <Error404 />,
      },
      {
        path: "/error",
        element: <Error404 />,
      },
      {
        path: "/",
        element: <Navigate to="/login" replace />,
      },
      {
        path: "/login",
        element: (
          <Suspense fallback={<Loader />}>
            <Login />
          </Suspense>
        ),
      },
      {
        path: "/mu",
        element: (
          <Suspense fallback={<Loader />}>
            <EntryMu />
          </Suspense>
        ),
      },
      {
        path: "/register",
        element: <Navigate to="/signup" replace={true} />,
      },
      {
        path: "/signup",
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={environmentData.RECAPTCHAKEY}>
            <Suspense fallback={<Loader />}>
              <SignUp />
            </Suspense>
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: "/signup/voice",
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={environmentData.RECAPTCHAKEY}>
            <Suspense fallback={<Loader />}>
              <SignUp />
            </Suspense>
          </GoogleReCaptchaProvider>
        ),
      },

      {
        path: "/auth/:provider",
        element: (
          <Suspense fallback={<Loader />}>
            <Redirect />
          </Suspense>
        ),
      },
      {
        path: "/authorize/:integrationType",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <RedirectUri />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/otp",
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={environmentData.RECAPTCHAKEY}>
            <Suspense fallback={<Loader />}>
              <Otp />
            </Suspense>
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: "/magic-link",
        element: (
          <Suspense fallback={<Loader />}>
            <MagicLink />
          </Suspense>
        ),
      },
      {
        path: "/ltd",
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={environmentData.RECAPTCHAKEY}>
            <Suspense fallback={<Loader />}>
              <SignUp />
            </Suspense>
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: "/calculator",
        element: (
          <Suspense fallback={<Loader />}>
            <Calculator />
          </Suspense>
        ),
      },
      {
        path: "/switchAccount",
        element: (
          <Suspense fallback={<Loader />}>
            <SwitchAccount />
          </Suspense>
        ),
      },

      {
        path: "/dashboard",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <Dashboard />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/builder/:botUrl",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              {environmentData.isAI ? <AiBotBuilder /> : <WorkflowBuilder />}
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/preview/:botUrl",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <Preview environment={"dev"} />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/integration",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <Subscriptions calledFromBuilder={false} />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/templates",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <Templates />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/audience",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <Audience />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/chats/*",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <Chats />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/settings/*",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <Settings />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/workspace/*",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <WorkspaceDetails />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/analytics/*",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <Analytics calledFromBuilder={false} />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/logout",
        element: (
          <Suspense fallback={<Loader />}>
            <Logout />
          </Suspense>
        ),
      },
      {
        path: "/forgotpassword",
        element: (
          <Suspense fallback={<Loader />}>
            <ForgotPassword />
          </Suspense>
        ),
      },
      {
        path: "/newpassword",
        element: (
          <Suspense fallback={<Loader />}>
            <NewPassword />
          </Suspense>
        ),
      },
      {
        path: "/newuser",
        element: (
          <Suspense fallback={<Loader />}>
            <NewUser />
          </Suspense>
        ),
      },
      {
        path: "/onboarding",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              {environmentData.isAI ? <Navigate to="/dashboard" /> : <Agent />}
            </Suspense>
          </AuthGuard>
        ),
      },
      // {
      //   path: "/old-onboarding",
      //   element: (
      //     <AuthGuard>
      //       <Suspense fallback={<Loader />}>
      //         <NewOnboarding redirect={false} />
      //       </Suspense>
      //     </AuthGuard>
      //   ),
      // },
      {
        path: "/text-to-workflow",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <TextToWorkflow />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/agent/:agentCode/:agentChatId?",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <Agent />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/bot-debug",
        element: (
          <AuthGuard>
            <Suspense fallback={<Loader />}>
              <BotDebug />
            </Suspense>
          </AuthGuard>
        ),
      },
      {
        path: "/lifetime",
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={environmentData.RECAPTCHAKEY}>
            <Suspense fallback={<Loader />}>
              <Coupon
                setIsApplied={() => {}}
                setShowCoupon={() => {}}
                setCode={() => {}}
              />
            </Suspense>
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: "/plan_expired",
        element: (
          <Suspense fallback={<Loader />}>
            <ExpiredPlan />
          </Suspense>
        ),
      },
      {
        path: "/trial_expired",
        element: (
          <Suspense fallback={<Loader />}>
            <ExpiredTrial />
          </Suspense>
        ),
      },
      {
        path: "/change_plan",
        element: (
          <Suspense fallback={<Loader />}>
            <ChangePlan />
          </Suspense>
        ),
      },
    ],
  },
]);
